import styled from 'styled-components'

export const FormButton = styled.button`
    width: 200px;
    height: 40px;
    border-radius: 15px;

    font-family: "MrsEavesItalic Regular";
    font-weight: 500;
    font-size: 25px;

    margin:20px;

	&:hover {
		background: rgba(100, 0, 0, .03);
	}
`
export const FormInput = styled.input`

  display: inline;
  border: 1px solid rgb(145, 145, 145);
  height: 40px;
  padding: 5px;
  width: 40%;
  margin-top: 5px;
  
  font-family: "MrsEavesItalic Regular";
  font-weight: 500;
  font-size: 25px;



  @media screen and (max-width: 960px) {
      width : 80vw;
    }      
`


export const FromBox = styled.div`
    margin: 0 auto;
    padding: 0px;
    position: relative;  
    height: auto;
   
`



export const FormRadioButton = styled.div`
  display: flex;
  padding: 10px 10px;
`

export const FormSection = styled.div`
    /* center */
    position: relative;  
    margin: 0 auto;
    padding: 0px;
`

export const FormText = styled.div`    
    font-weight: 400;
    font-style: normal;
    line-height: 1;
    letter-spacing: 1.5px;
    font-size: 18.2px;
    padding-bottom: 5px;
    padding-top: 15px;
`

export const FormSelect = styled.select`
  display: inline;
  width: 400px;
  height: 30px;
  border: 1px dotted rgb(145, 145, 145);
`
