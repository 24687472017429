import styled from 'styled-components';

const Wraper = styled.div`

  text-align: center;
  
  //border: 2px dashed #dddddd;    
  padding: 15px;

  margin: 0 auto;
  margin-bottom: 20px;

  height: auto;
  width: 60vw;  

  @media screen and (max-width: 960px) {
    width: 90vw;  
  }

`


export default Wraper;